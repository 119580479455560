$white: #ffffff;
$success: #27ae60;
$gray: #e8eff2;
$primary: #3498db;
$blue: #03a9f4;
$danger: #f4516c;
$info: #36a3f7;
$teal: #00c5dc;
$indigo: #967adc;
$warning: #f6bb42;
$dark: #212121;
$muted: #909fa7;

$success-rhf: #3498db;

$danger-rhf: #e74c3c;

$card-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
$nav-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);

$width: 100%;
$height: 100%;

$font-secondary: "Montserrat", sans-serif;
